<template>
  <v-sheet
    height="36"
    class="primary d-flex align-center"
  >
    <v-container class="d-flex justify-space-between align-center py-0 scroll-y">
      <div v-if="!$vuetify.breakpoint.mobile">
        <a class="white--text caption text-xl-body-1 mr-5">
          <v-icon
            class="mr-1"
            small
            dark
            >fas fa-phone-alt</v-icon
          >
          (11) 2679-2007
        </a>
        <a class="white--text caption text-xl-body-1">
          <v-icon
            class="mr-1"
            small
            dark
            >fas fa-envelope</v-icon
          >
          vendas@magazinebrindes.com.br
        </a>
      </div>
      <div v-else>
        <v-btn
          @click="$store.commit('openSiteMenu')"
          icon
        >
          <v-icon color="white">fas fa-bars</v-icon>
        </v-btn>
      </div>
      <div class="d-flex align-center">
        <SocialButtons
          color="primary"
          bg="white"
          class="u-border-right"
        />

        <div>
          <v-dialog max-width="800">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                text
                :x-small="!$vuetify.breakpoint.xl"
                dark
              >
                <v-icon
                  small
                  left
                  >fas fa-question</v-icon
                >
                Ajuda
              </v-btn>
            </template>
            <v-card v-if="faq != null">
              <v-card-title class="primary--text">
                Dúvidas frequentes
              </v-card-title>
              <v-card-text>
                <v-expansion-panels focusable>
                  <v-expansion-panel
                    v-for="(item, i) in faq"
                    :key="i"
                  >
                    <v-expansion-panel-header
                      class="secondary--text font-weight-bold text-xl-h6"
                      >{{ item.title }}</v-expansion-panel-header
                    >
                    <v-expansion-panel-content class="text-xl-h6">
                      <div
                        class="pt-4"
                        v-html="item.description"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  components: {
    SocialButtons: () => import('./widgets/SocialButtons'),
  },
  data: () => ({
    faq: null,
  }),
  methods: {
    getFaqs() {
      this.$api
        .get(`faqs/index`)
        .then((res) => {
          this.faq = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
  mounted() {
    this.getFaqs()
  },
}
</script>

<style>
.u-border-right {
  border-right: 1px solid white;
  padding-right: 7px;
}
</style>
